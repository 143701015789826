<template>
  <div class="subscribe-combine-edit">
    <PageTitle class="page-title" :title="title" icon="chevron_left" hideBtn @iconClick="backToList" />
    <div v-if="loading" v-loading="loading" />
    <template v-if="!loading">
      <SubscribeInfoBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :contractData="contractData"
      />
      <SubscribeContentBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :contractData="contractData"
      />
    </template>

    <PageFixedFooter @cancel="backToList" @confirm="onSubmit" />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import SubscribeInfoBlock from './components/SubscribeInfoBlock.vue'
import SubscribeContentBlock from './components/SubscribeContentBlock.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import FormsContext from './formsContext'
import { FindSubscribeContract, UpdateSubscribeContract, CreateSubscribeContract } from '@/api/subscribe'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'SubscribeContractEdit',
  components: {
    PageFixedFooter,
    SubscribeInfoBlock,
    SubscribeContentBlock,
    PageTitle,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const contractId = computed(() => route.params.id)
    const title = computed(() => {
      if (route.query.edit) return '編輯訂閱合約'
      if (route.query.view) return '查看訂閱合約'
      return '新增訂閱合約'
    })
    const contractData = ref(null)
    const loading = ref(false)

    const backToList = () => {
      router.push({
        name: 'SubscribeContractList',
      })
    }

    const findSubscribeContract = async () => {
      loading.value = true
      const [res, err] = await FindSubscribeContract({
        shopId: shopId.value,
        id: contractId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      contractData.value = res
    }

    const createSubscribeContract = async (payload) => {
      loading.value = true
      const [, err] = await CreateSubscribeContract({
        shopId: shopId.value,
        ...payload,
      })
      if (err) throw err
      window.$message.success('新增成功!')
    }

    const updateSubscribeContract = async (payload) => {
      console.log('payload', payload)
      loading.value = true
      const [, err] = await UpdateSubscribeContract({
        shopId: shopId.value,
        id: contractId.value,
        ...payload,
      })
      if (err) throw err
      window.$message.success('更新成功！')
    }

    const onSubmit = async () => {
      if (route.query.view) return backToList()
      const allPass = await FormsContext.validateAll()
      if (allPass) {
        const data = FormsContext.composFormData()
        const postBody = {
          ...data,
        }
        try {
          if (!contractId.value) await createSubscribeContract(postBody)
          if (contractId.value) await updateSubscribeContract(postBody)
          backToList()
        } catch (error) {
          window.$message.error(error)
          loading.value = false
        }
      }
    }

    onMounted(() => {
      if (contractId.value) findSubscribeContract()
    })

    return {
      backToList,
      onSubmit,
      loading,
      FormsContext,
      contractData,
      title,
    }
  },
})
</script>

<style lang="postcss" scoped>
.subscribe-combine-edit {
  @apply flex flex-col gap-[20px];
}
.page-title {
  @apply mb-0
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
